import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import APIConfig from "../Configuration/APIConfig";
export default function Contact() {
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-3">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="bg-white shadow-md rounded h-100 p-3">
                                    <iframe className="h-100 w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.1652197540316!2d78.45348020246227!3d17.4038569411322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb974013d5ecb5%3A0xb2a4cc668fe8d285!2sAshoka%20Plaza%2C%20Saifabad%20Rd%2C%20Ambedkar%20Nagar%2C%20Masab%20Tank%2C%20Hyderabad%2C%20Telangana%20500004!5e0!3m2!1sen!2sin!4v1713361791498!5m2!1sen!2sin" allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white shadow-md rounded p-4">
                                    <h4 className="text-6 mb-4">Get in touch</h4>
                                    <hr className="mx-n4 mb-4" />
                                    <p className="text-3">For Customer Support and Query, Get in touch with us:</p>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h5>GRR TOURS AND TRAVELS</h5>
                                        <p>Shop No: 111 & 112, 1st Floor, Ashoka Plaza <br />
                                         Beside Golkonda Hotel,<br />
                                         Masab Tank, Hyderabad,<br /> 
                                         Telangana 500004</p>
                                    </div>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h5>Telephone</h5>
                                        <p>(+91) {APIConfig.COMAPNYPHONE}</p>
                                    </div>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h5>Travel Inquiries</h5>
                                        <p>{APIConfig.COMPANYEMAIL}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}