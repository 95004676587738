import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import APIConfig from "../Configuration/APIConfig";
export default function ResetPassword() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const handleSubmit = (e) => {
        setBtnSubmit(false)
        e.preventDefault();
        if (password !== "") {
            const formData = new FormData()
            formData.append("email", email)
            formData.append("token", token.trim())
            formData.append("password", password)
            addOrEdit(formData);
        }
        else {
            alert("Please enter password")
        }
    };
    const applicationAPI = () => {
        return {
            post: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.RESETPASSWORD, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .post(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    setSuccessStatus(res.data.message);
                    setBtnSubmit(true);
                    setPassword("");
                    setErrorStatus('')
                }
                else {
                    setBtnSubmit(true);
                    if(res.data.message=="Invalid token."){
                    setErrorStatus("Token Expired.");
                    }
                    else{
                        setErrorStatus(res.data.message);
                    }
                    setSuccessStatus('');
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        if (searchParams === null) {
            navigate('/')
        }
        else {
            setEmail(searchParams.get("email"))
            //setToken(searchParams.get("token"))
            console.log(searchParams.toString())
            var letparams=searchParams.toString();
            var splitparams=letparams.split('=')
            setToken(splitparams[2])
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        <div className="row justify-content-center align-items-center m-auto">
                            <div className="col-12">
                                <div className="bg-mode shadow rounded-3 overflow-hidden">
                                    <div className="row g-0">
                                        {/* Vector Image */}
                                        <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                            <div className="p-3 p-lg-5">
                                                <img src="/assets/img/auth.svg" className="img-fluid" alt />
                                            </div>
                                            {/* Divider */}
                                            <div className="vr opacity-1 d-none d-lg-block" />
                                        </div>
                                        {/* Information */}
                                        <div className="col-lg-6 order-1">
                                            <div className="p-4 p-sm-7">
                                                <h1 className="mb-2 fs-2">Reset Password</h1>
                                                {successStatus !== "" ? <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {successStatus}</div> : ""}
                                                {errorStatus !== "" ? <div class="alert alert-danger shadow-md"> <i class="fas fa-check-circle"></i> {errorStatus} <Link to={"/forget-password"}>Please click forget password</Link> </div> : ""}
                                                <p className="mb-0">Please enter new password</p>
                                                {/* Form START */}
                                                <form onSubmit={handleSubmit} className="mt-4 text-start" autoComplete="off" noValidate>
                                                    <div className="form py-4">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input disabled className={'form-control' + applyErrorClass('email')} name="email" type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter Email" />
                                                            {errors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                                        </div>
                                                        <div className="form-group">
                                                            <label>New Password</label>
                                                            <input className={'form-control' + applyErrorClass('password')} name="password" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter New Password" />
                                                            {errors.password === false ? (<div className="validationerror"> Please enter password </div>) : ('')}
                                                        </div>
                                                        <div className="form-group">
                                                            {btnSubmit === true ? (
                                                                <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Reset Password</button>
                                                            ) : (<button type="button" disabled className="btn btn-primary full-width font--bold btn-lg">Please wait...</button>)}
                                                        </div>
                                                    </div>
                                                    {/* Copyright */}
                                                    <div className="text-primary-hover mt-3 text-center"> Copyrights ©2024 GRR Travels & Tourism</div>
                                                </form>
                                                {/* Form END */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}