import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import UserTab from "./UserTab";
import UserMobileTab from "./UserMobileTab";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { handleSuccess, handleError } from "../Layout/CustomAlerts";
import { useNavigate } from "react-router-dom";
import auth from '../Authentication/Auth';
export default function Profile() {
    const navigate = useNavigate();
    const initialFieldValues = {
        id: "00000000-0000-0000-0000-000000000000",
        name: "",
        email: "",
        phoneNumber: "",
        isActive: true
    };
    const initialPasswordFieldValues = {
        userId: "00000000-0000-0000-0000-000000000000",
        oldPassword: "",
        newPassword: ""
    };
    const [values, setValues] = useState(initialFieldValues);
    const [passwordValues, setPasswordValues] = useState(initialPasswordFieldValues);
    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handlePasswordInputChange = (e) => {
        const { name, value } = e.target;
        setPasswordValues({
            ...passwordValues,
            [name]: value,
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const validate = () => {
        let temp = {};
        temp.name = values.name === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.phoneNumber = values.phoneNumber === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const validatePassword = () => {
        let temp = {};
        temp.oldPassword = passwordValues.oldPassword === "" ? false : true;
        temp.newPassword = passwordValues.newPassword === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData()
            formData.append("id", localStorage.getItem('userId'))
            formData.append("name", values.name)
            formData.append("email", values.email)
            formData.append("phoneNumber", values.phoneNumber)
            formData.append("isActive", true)
            addOrEdit(formData);
        }
    };
    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (validatePassword()) {
            const formData = new FormData()
            formData.append("userId", localStorage.getItem('userId'))
            formData.append("oldPassword", passwordValues.oldPassword)
            formData.append("newPassword", passwordValues.newPassword)
            changePassword(formData);
        }
    };
    const applicationAPI = () => {
        return {
            update: (newrecord) => axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEUSER, newrecord, { ...headerconfig }),
            change: (newrecords) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CHANGEPASSWORD, newrecords, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .update(formData)
            .then((res) => {
                var responseData = res.data.response;
                console.log(responseData)
                if (responseData.succeeded === true) {
                    GetUserData();
                    handleSuccess('Profile Updated');
                }
                else {
                    handleError(responseData.message);
                }
            });
    };
    const changePassword = (formData) => {
        applicationAPI()
            .change(formData)
            .then((res) => {
                var responseData = res.data.response;
                if (responseData.succeeded === true) {
                    handleSuccess(responseData.message);
                }
                else {
                    handleError(responseData.message);
                }
            });
    };
    const GetUserData = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETUSERBYID + "?Id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded) {
                    setValues(response.data.response.data);
                }
                else{
                    auth.ulogout(() => {
                        localStorage.clear();
                        navigate("/")
                    })
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetUserData();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="dashboard-menus border-top d-none d-lg-block" style={{ backgroundColor: 'cadetblue' }}>
                    <div className="container">
                        <UserTab></UserTab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <UserMobileTab></UserMobileTab>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-6 col-lg-8 col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-file-invoice me-2" />Personal Information</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <div className="row align-items-center justify-content-start">
                                                <div className="col-xl-12 col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" value={values.name} name="name" onChange={handleInputChange} className={"form-control" + applyErrorClass('name')} placeholder="Name" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Email ID</label>
                                                        <input disabled type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Mobile</label>
                                                        <input type="text" value={values.phoneNumber} name="phoneNumber" onChange={handleInputChange} className={"form-control" + applyErrorClass('phoneNumber')} placeholder="Moile" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-md btn-primary mb-0">Update Profile</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-lock me-2" />Update Password</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handlePasswordSubmit} autoComplete="off" noValidate>
                                            <div className="row align-items-center justify-content-start">
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Old Password</label>
                                                        <input type="text" value={values.oldPassword} name="oldPassword" onChange={handlePasswordInputChange} className={"form-control" + applyErrorClass('oldPassword')} placeholder="*************" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">New Password</label>
                                                        <input type="text" value={values.newPassword} name="newPassword" onChange={handlePasswordInputChange} className={"form-control" + applyErrorClass('newPassword')} placeholder="*************" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-md btn-primary mb-0">Change Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}