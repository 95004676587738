import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const initialFieldValues = {
    packageEnquiryId: "00000000-0000-0000-0000-000000000000",
    destinationId: "00000000-0000-0000-0000-000000000000",
    customerName: "",
    email: "",
    mobile: "",
    status: "NEW",
    travelDate: new Date(),
    returnDate: new Date(),
    travellers: 0,
    nights: 0,
    category: 4,
    notes: "",
    flight: "on",
    hotel: "on",
    transfers: "on",
    sightseeing: "on",
    visa: "on",
    tourType: "FAMILY"
};
export default function TourDetails() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [tourDetail, setTourDetail] = useState({});
    const [tourInclusion, setTourInclusion] = useState([]);
    const [tourExclusion, setTourExclusion] = useState([]);
    const [tourItinerary, setTourItinerary] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const selectToDate = (nextdate) => {
        var todate = new Date();
        if (nextdate === undefined) {
            todate = addDays(fromDate)
        }
        else {
            todate = nextdate;
        }
        if (todate < fromDate) {
            alert("To Date should be greater than are equal to From Date")
            addDays(fromDate);
        }
        else {
            setFromDate(fromDate);
            setToDate(todate);
        }
    };
    const addDays = (cindate) => {
        console.log(cindate)
        console.log(toDate)
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        if (newDate > toDate) {
            newDate.setDate(cindate.getDate() + 1);
            setToDate(newDate);
        }
        else {
            setToDate(toDate)
        }
        setFromDate(cindate);
        return newDate;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.customerName = values.customerName === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        temp.nights = values.nights === 0 ? false : true;
        temp.holidayDestinationId = values.holidayDestinationId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("packageEnquiryId", "00000000-0000-0000-0000-000000000000")
            formData.append("destinationId", tourDetail.destinationId)
            formData.append("customerName", values.customerName)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("travelDate", moment(values.travelDate).format('YYYY-MM-DD'))
            formData.append("returnDate", moment(values.returnDate).format('YYYY-MM-DD'))
            formData.append("nights", values.nights)
            formData.append("travellers", values.travellers)
            formData.append("category", values.category)
            formData.append("flight", values.flight)
            formData.append("hotel", values.hotel)
            formData.append("sightseeing", values.sightseeing)
            formData.append("visa", values.visa)
            formData.append("transfers", values.transfers)
            formData.append("notes", values.notes)
            formData.append("tourType", values.tourType)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEPACKAGEENQUIRY, newrecord, { ...headerconfig })
        };
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const addOrEdit = (formData) => {
        if (formData.get('packageEnquiryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setStatus("Enquiry Submitted Successfully");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        setStatus("");
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    setStatus("");
                    setBtnSubmit(true);
                });
        }
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetTourDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETTOURBYID + "/" + params.tourId, { ...headerconfig })
            .then((response) => {
                setTourDetail(response.data.data);
                if (response.data.data != null) {
                    setTourInclusion(response.data.data.tourInclusions);
                    setTourExclusion(response.data.data.tourExclusions);
                    setTourItinerary(response.data.data.tourItineraries);
                }
                setLoading(false)
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetTourDetails();
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <AgentHeader></AgentHeader>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">{tourDetail.destinationName}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card border-0 p-3 mb-4">
                                        <div className="crd-heaader d-md-flex align-items-center justify-content-between">
                                            <div className="crd-heaader-first">
                                                <div className="d-block">
                                                    <h4 className="mb-0">{tourDetail.name}, {tourDetail.destinationName}</h4>
                                                    <div className="exlops">
                                                        <p className="detail ellipsis-container fw-semibold">
                                                            <span className="ellipsis-item__normal">{tourDetail.nights} Nights {parseInt(tourDetail.nights + 1)} Days</span>
                                                            <span className="separate ellipsis-item__normal" />
                                                            <span className="ellipsis-item">{tourDetail.type}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row">
                                        {/* Details */}
                                        <div className="col-xl-9 col-lg-9 col-md-12">
                                            <div className="mb-lg-0 mb-3">
                                                <div className="relative d-flex">
                                                    <img src={tourDetail.imageSrc} style={{ width: "100%" }} alt="image" className="rounded-2 img-fluid" />
                                                </div>
                                            </div>
                                            <div className="tab-content" id="pillstour-tabContent">
                                                {/* Overview Info */}
                                                <div className="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab" tabIndex={0}>
                                                    <div className="overview-wrap full-width">
                                                            <div className="card mb-4 border rounded-3">
                                                                <div className="card-header">
                                                                    <h4 className="fs-5">Overview</h4>
                                                                </div>
                                                                <div className="card-body">
                                                                    <p className="mb-0">{tourDetail.description}</p>
                                                                </div>
                                                            </div>
                                                        <div className="card border rounded-3 mb-4">
                                                            <div className="card-header">
                                                                <h4 className="fs-5">Inclusions &amp; Exclusions</h4>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="expott-info mb-4">
                                                                    <h5>Inclusions</h5>
                                                                    <ul className="row align-items-center p-0 g-3">
                                                                        {tourInclusion.length > 0 && tourInclusion.map(t =>
                                                                            <li className="col-md-12">
                                                                                <i className="fa-regular fa-circle-dot text-success me-2" />{t.inclusion}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div className="expott-info">
                                                                    <h5>Exclusions</h5>
                                                                    <ul className="row align-items-center p-0 g-3">
                                                                        {tourExclusion.length > 0 && tourExclusion.map(t =>
                                                                            <li className="col-md-12">
                                                                                <i className="fa-regular fa-circle-dot text-danger me-2" />{t.exclusion}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                            {tourItinerary.length > 0 && tourItinerary.map(t =>
                                                                <div className="accordion-item border">
                                                                    <h2 className="accordion-header rounded-2">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-day1" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                            <span className="fw-bold me-2">{t.title}</span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="flush-day1" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                                                                        <div className="accordion-body">
                                                                            <div className="exportial mb-3">
                                                                                <p className="mb-0">{t.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Sidebar */}
                                        <div className="col-xl-3 col-lg-3 col-md-12">
                                            <div className="sides-block">
                                                <div className="card border rounded-3 mb-4">
                                                    <div className="single-card px-3 py-3">
                                                        <p className="font12 lh-1 mb-0"><span className="text-dark fs-3 fw-bold"><span>INR {tourDetail.totalSalePrice}</span></span> per
                                                            person*</p>
                                                    </div>
                                                    <span className="badge bg-success p-6 full-width fw-medium text-uppercase mb-2">Please enter the following details</span>
                                                    <div className="single-card px-3 py-3">
                                                        {status !== "" ?
                                                            <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {status} </div>
                                                            : ""}
                                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" maxLength={45} value={values.customerName} name="customerName" onChange={handleInputChange} className={"form-control" + applyErrorClass('customerName')} placeholder="Please enter customer name" />
                                                                <label>Name</label>
                                                                {errors.customerName === false ? (<div className="validationerror">Please enter customer name </div>) : ('')}
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" maxLength={45} value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                                                <label>Email</label>
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" maxLength={15} value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please enter mobile" />
                                                                <label>Phone</label>
                                                                {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="mb-3">
                                                                    <label htmlFor="status" className="form-label">Travel Date</label>
                                                                    <div className="input-group">
                                                                        <ReactDatePicker className="form-control" wrapperClassName="datePicker" name="travelDate" selected={values.travelDate} onChange={(date) => setValues({ ...values, ['travelDate']: date })} dateFormat="EEE dd MMM yyyy" minDate={new Date()} />
                                                                    </div>
                                                                    {errors.travelDate === false ? (<div className="validationerror">Please select travelDate </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="nights" className="form-label">Nights*</label>
                                                                        <input min={1} type="number" className={"form-control" + applyErrorClass('nights')} value={values.nights} name="nights" onChange={handleInputChange}></input>
                                                                        {errors.nights === false ? (<div className="validationerror">Nights </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="travellers" className="form-label">Travellers</label>
                                                                        <input min={1} type="number" className={"form-control" + applyErrorClass('travellers')} value={values.travellers} name="travellers" onChange={handleInputChange}></input>
                                                                        {errors.travellers === false ? (<div className="validationerror">Please select travellers </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="mb-3">
                                                                    <label htmlFor="notes" className="form-label">Comments</label>
                                                                    <input type="text" value={values.notes} name="notes" onChange={handleInputChange} className={"form-control" + applyErrorClass('notes')} placeholder="Beach side villa, Veg Food..." />
                                                                    {errors.notes === false ? (<div className="validationerror">Please enter tour requirements </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <select className="form-select" value={values.tourType} name="tourType" onChange={handleInputChange}>
                                                                    <option value="FAMILY">FAMILY TOUR</option>
                                                                    <option value="GROUP">GROUP TOUR</option>
                                                                    <option value="HONEYMOON">HONEY MOON</option>
                                                                    <option value="CORPORATE">CORPORATE</option>
                                                                </select>
                                                                <label>Vacation Type</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                                    {
                                                                        btnSubmit === true ?
                                                                            <button type="submit" className="btn btn-primary w-100">{values.packageEnquiryId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                                            :
                                                                            <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                                                    }
                                                                    <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}