import HomeBanner from "../Home/HomeBanner";
import HomeContent from "../Home/HomeContent";
import PopularDestination from "../Home/PopularDestinations";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import VisaCountries from "../Data/VisaCountries.json";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect, useRef } from "react";
export default function SearchVisa() {
    const navigate = useNavigate();
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [visaCountry, setVisaCountry] = useState('United Arab Emirates');
    const [visaCountryId, setVisaCountryId] = useState('00000000-0000-0000-0000-000000000000');
    const [departureDate, setDepartureDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [errors, setErrors] = useState({});
    const [departureResults, setDepartureResults] = useState([]);
    const inputDRef = useRef(null);
    const handleDepartureAirportSearch = (e) => {
        const value = e.target.value;
        setVisaCountry(value);
        if (value.length > 0) {
            const filteredAirports = VisaCountries
                .filter(airport =>
                    airport.name.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 5);
            setDepartureResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = VisaCountries
                    .filter(airport =>
                        airport.name.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 5);
                setDepartureResults(filteredAirports1);
            }
        } else {
            const filteredAirports1 = VisaCountries.filter(airport =>
                airport.name.toLowerCase().includes(value.toLowerCase())
            )
                .slice(0, 5);
            setDepartureResults(filteredAirports1);
        }
    };
    const handleDepartureAirportClick = (e) => {
        let filteredAirports = VisaCountries.filter(function (p) {
            return p.display === true
        }).slice(0, 5);
        setDepartureResults(filteredAirports);
        inputDRef.current.select();
    };
    const handleDepartureSelect = (data) => {
        var DF = data.name;
        setVisaCountryId(data.url)
        setVisaCountry(DF);
        setDepartureResults([]);
        setErrors([]);
    }
    const validate = () => {
        let temp = {};
        temp.departureDate = departureDate === "" ? false : true;
        temp.adults = adults === 0 ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            navigate('/visas/' + visaCountryId + "/" + adults + "/" + moment(departureDate).format('YYYY-MM-DD'));
        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleIncreaseAdults = (e) => {
        setAdults(parseFloat(adults + 1))
    }
    const handleDecreaseAdults = (e) => {
        if (adults !== 1) {
            setAdults(parseFloat(adults - 1))
        }
    }
    const handleDepartureDate = (date) => {
        if (date !== null) {
            setDepartureDate(date)
        }
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    return (
        <div id="main-wrapper">
            <Header></Header>
            <div className="image-cover hero-header bg-white" style={{ background: 'url(/assets/img/2151022224.jpg)' }} data-overlay={1}>
                <div className="container">
                    {/* Search Form */}
                    <div className="row justify-content-between align-items-left g-4">
                        <HomeBanner></HomeBanner>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="searchableForm bg-white rounded-3 py-3 px-4">
                                <div className="searchableCaps">
                                    <div className="search-wrap">
                                        <div className="tab-content pt-4">
                                            <div className="tab-pane show active" id="hotels">
                                                <div className="row gx-lg-2 g-3">
                                                    <div className="col-xl-12 col-lg-12 col-md-12">
                                                        <div className="row gy-3 gx-lg-2 gx-3">
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                                <div className="form-group hdd-arrow mb-0">
                                                                    <span className="text-dark">Country</span>
                                                                    <div className="position-relative">
                                                                    <input ref={inputDRef} className="form-control" type="text" placeholder="Search country" value={visaCountry} onChange={handleDepartureAirportSearch} onClick={handleDepartureAirportClick} onFocus={handleDepartureAirportClick} />
                                                                    </div>
                                                                    {errors.displayFrom === false ? (<div className="validationerror">Please select country </div>) : ('')}
                                                                    {departureResults.length > 0 && (
                                                                        <ul className="list-group">
                                                                            {departureResults.map((airport) => (
                                                                                <li className="list-group-item list-group-item-action" key={airport.visaCountryId} onClick={() => handleDepartureSelect(airport)}>
                                                                                    <div className="item-details">
                                                                                        <span className="item-destination">{airport.name}</span>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-12 col-md-12">
                                                                <span className="text-dark">Travel Date</span>
                                                                <div className="position-relative">
                                                                    <ReactDatePicker className="form-control" wrapperClassName="datePicker" name="departureDate" selected={departureDate} onChange={(date) => handleDepartureDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                                                    <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                                    {errors.departureDate === false ? (<div className="validationerror">Please select departureDate </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                                <span className="text-dark">Applicants</span>
                                                                <div className="form-group mb-0">
                                                                    <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                                                        <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}>{adults + " Travellers"}</button>
                                                                        <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options" style={{ minHeight: "40px", top: "65px" }}>
                                                                            <div>
                                                                                <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={handleDecreaseAdults}><i className="fa-solid fa-minus" /></span>
                                                                                <span className="guests-input__value"><span defaultValue={1} readOnly value={adults} name="adults">{adults}</span>Travellers</span>
                                                                                <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={handleIncreaseAdults}><i className="fa-solid fa-plus" /></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-12 col-md-12">
                                                                <div className="form-group mb-0" style={{ paddingTop: "25px" }}>
                                                                    <button type="button" className="btn btn-primary full-width fw-medium" onClick={handleSearch}>Search Visas<i className="fa-solid fa-location-arrow ms-2" /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </row> */}
                </div>
            </div>
            <HomeContent></HomeContent>
            <PopularDestination></PopularDestination>
            <Footer></Footer>
        </div>
    );
}