import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { useEffect, useState } from "react";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PageLoader from "../Layout/PageLoader";
import countries from '../Data/Countries.json';
export default function OneWayFlight() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [errors, setErrors] = useState({});
    const [searchData, setSearchData] = useState({});
    const [flightData, setFlightData] = useState([])
    const [flightPriceData, setFlightPriceData] = useState([])
    const [segments, setSegments] = useState([])
    const [supplierIds, setSupplierIds] = useState([])
    const [supplierBookingId, setSupplierBookingId] = useState('')
    const [baseprice, setBasePrice] = useState(0)
    const [tax, setTax] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalFare, setTotalFare] = useState(0)
    const [convenienceFee, setConvenienceFee] = useState(0)
    const [gst, setGST] = useState(0)
    const [gstAmount, setGSTAmount] = useState(0)
    const [adultTravellers, setAdultTravellers] = useState([]);
    const [kidTravellers, setKidTravellers] = useState([]);
    const [infantTravellers, setInfantTravellers] = useState([]);
    const [totalTravellers, setTotalTravellers] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState(false);
    const [proceed, setProceed] = useState(true);
    const [priceChange, setPriceChange] = useState('');
    const [paymentGatewayFee, setPaymentGatewayFee] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    function getformtravelData(tData) {
        var gender = "MALE";
        if (tData.title === "Mr") {
            gender = "MALE";
        }
        else if (tData.title === "Mrs") {
            gender = "FEMALE";
        }
        else if (tData.title === "Ms") {
            gender = "FEMALE";
        }
        else if (tData.title === "Master") {
            gender = "MALE";
        }
        const myObject = {
            travelerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            firstName: tData.firstName,
            lastName: tData.lastName,
            title: tData.title,
            gender: gender,
            travelerType: tData.type,
            //dateOfBirth: moment(tData.dateOfBirth).format('YYYY-MM-DD'),
            dateOfBirth: params.isDomestic === 'INTERNATIONAL' ? moment(tData.dateOfBirth).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            passportNo: tData.passportNo,
            passportNationality: tData.passportNationality,
            passbookIssueDate: params.isDomestic === 'INTERNATIONAL' ? moment(tData.passbookIssueDate).format('YYYY-MM-DD') : "",
            passbookExpireDate: params.isDomestic === 'INTERNATIONAL' ? moment(tData.passbookExpireDate).format('YYYY-MM-DD') : "",
        };
        return myObject;
    }
    function getGSTInfo() {
        const gstinfo = {
            gstNumber: APIConfig.COMPANYGST,
            email: email,
            registeredName: APIConfig.COMPANYNAME,
            mobile: mobile,
            address: APIConfig.COMPANYCITY
        };
        return gstinfo;
    }
    function getPaymentInfo() {
        const myobject = [{
            amount: totalFare
        }];
        return myobject;
    }
    function getDeliveryinfo() {
        const emails = [email];
        const contacts = [mobile];
        const deliveryinfo = {
            emails: emails,
            contacts: contacts
        };
        return deliveryinfo;
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handleMobileChange = (e) => {
        setMobile(e.target.value)
    }
    const validate = (index, adata) => {
        let temp = {};
        temp.firstName = adata.firstName === "" ? false : true;
        temp.lastName = adata.lastName === "" ? false : true;
        if (params.isDomestic === 'INTERNATIONAL') {
            temp.dateOfBirth = adata.dateOfBirth === "" ? false : true;
            temp.passportNo = adata.passportNo === "" ? false : true;
            temp.passportNationality = adata.passportNationality === "" ? false : true;
            temp.passbookIssueDate = adata.passbookIssueDate === "" ? false : true;
            temp.passbookExpireDate = adata.passbookExpireDate === "" ? false : true;
        }
        temp.email = email === "" ? false : true;
        temp.mobile = mobile === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleFlightSearch = (e) => {
        navigate('/searchflights');
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        var temptravellerinfos = [];
        for (var i = 0; i < adultTravellers.length; i++) {
            if (validate(i, adultTravellers[i])) {
                var tempData = getformtravelData(adultTravellers[i]);
                temptravellerinfos = [...temptravellerinfos, tempData];
            }
        }
        for (var i = 0; i < kidTravellers.length; i++) {
            if (validate(i, kidTravellers[i])) {
                var tempData = getformtravelData(kidTravellers[i]);
                temptravellerinfos = [...temptravellerinfos, tempData];
            }
        }
        for (var i = 0; i < infantTravellers.length; i++) {
            if (validate(i, infantTravellers[i])) {
                var tempData = getformtravelData(infantTravellers[i]);
                temptravellerinfos = [...temptravellerinfos, tempData];
            }
        }
        if (errorAdult !== "" || errorKid !== "" || errorInfant !== "") {
            alert("Please check the errors");
        }
        else if (temptravellerinfos.length === totalTravellers) {
            const flightBookingRequest = {
                isDomestic: params.isDomestic,
                flightBookingId: "00000000-0000-0000-0000-000000000000",
                supplierPriceId: supplierIds,
                supplierBookingId: supplierBookingId,
                supplierId: state.flightsdata.supplierId,
                travellerInfos: [],
                paymentInfos: getPaymentInfo(),
                gstInfo: getGSTInfo(),
                deliveryInfo: getDeliveryinfo(),
                tfSegments: segments,
                tfPriceDetails: flightPriceData,
                flightSearchRequest: state.searchData,
                transactionNo: "",
                paymentStatus: "",
                email: email,
                mobile: mobile,
                basePrice: baseprice,
                tax: tax,
                totalPrice: totalPrice,
                totalFare: totalFare,
                gst: gst,
                gstAmount: gstAmount,
                discount: discount,
                convenienceFee: convenienceFee,
                paymentGatewayFee: paymentGatewayFee,
                requestedBy:"CUSTOMER"
            }
            flightBookingRequest.travellerInfos = temptravellerinfos;
            CheckPrice(flightBookingRequest, state.searchData);
        }
        else {
            setLoading(false);
            alert("Please enter travelers data");
        }
    };
    const CheckPrice = (flightBookingRequest, searchQuery) => {
        axios.post(APIConfig.APIACTIVATEURL + APIConfig.REVIEWFLIGHT, flightBookingRequest, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                setLoading(false)
                if (!responseData.succeeded) {
                    if (responseData.message == "NOTAVAILABLE") {
                        setProceed(false)
                        navigate('/oneway-flights/' + params.adults + "/" + params.kids + "/" + params.infants + "/" + params.isDomestic, { state: { searchQuery } });
                    }
                    else {
                        flightBookingRequest = responseData.data;
                        navigate('/oneway-flights/' + params.adults + "/" + params.kids + "/" + params.infants + "/" + params.isDomestic, { state: { flightBookingRequest, flightData, searchQuery } });
                    }
                }
                else {
                    flightBookingRequest = responseData.data;
                    if (flightBookingRequest.loginResponse !== null) {
                        localStorage.setItem('userId', flightBookingRequest.loginResponse.userId);
                        localStorage.setItem('name', flightBookingRequest.loginResponse.name);
                        localStorage.setItem('roleName', flightBookingRequest.loginResponse.role);
                        localStorage.setItem('userToken', flightBookingRequest.loginResponse.token);
                        localStorage.setItem('tokenexpiration', flightBookingRequest.loginResponse.expiration);
                    }
                    navigate('/oneway-review/' + params.adults + "/" + params.kids + "/" + params.infants + "/" + params.isDomestic, { state: { flightBookingRequest, flightData, searchQuery } });
                }
            });
    }
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    const handleAdultChange = (index, event) => {
        const values = [...adultTravellers];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;
        setAdultTravellers(values);
    };
    const [errorAdult, setAdultError] = useState('');
    const [errorKid, setKidError] = useState('');
    const [errorInfant, setInfantError] = useState('');
    const validateAdultDate = (index, event) => {
        const inputDate = new Date(event.target.value);
        const year = inputDate.getFullYear().toString();
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 100;
        if (year.length > 4 && event.target.value) {
            setAdultError('The year must be 4 digits!');
            event.target.value = ''; // Clear the input value
        }
        if (year < minYear || year > currentYear) {
            setAdultError(`The year must be between ${minYear} and ${currentYear}!`);
            event.target.setCustomValidity(`The year must be between ${minYear} and ${currentYear}`);
            event.target.value = ''; // Clear the input value
        }
        else {
            const values = [...adultTravellers];
            const updatedValue = event.target.name;
            values[index][updatedValue] = event.target.value;
            setAdultTravellers(values);
            setAdultError('');
        }
    };
    const validateKidsDate = (index, event) => {
        const inputDate = new Date(event.target.value);
        const year = inputDate.getFullYear().toString();
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 12;
        if (year.length > 4 && event.target.value) {
            setKidError('The year must be 4 digits!');
            event.target.value = ''; // Clear the input value
        }
        if (year < minYear || year > currentYear) {
            setKidError(`The year must be between ${minYear} and ${currentYear}!`);
            event.target.setCustomValidity(`The year must be between ${minYear} and ${currentYear}`);
            event.target.value = ''; // Clear the input value
        }
        else {
            const values = [...kidTravellers];
            const updatedValue = event.target.name;
            values[index][updatedValue] = event.target.value;
            setKidTravellers(values);
            setKidError('');
        }
    };
    const validateInfantDate = (index, event) => {
        const inputDate = new Date(event.target.value);
        const year = inputDate.getFullYear().toString();
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 2;
        if (year.length > 4 && event.target.value) {
            setInfantError('The year must be 4 digits!');
            event.target.value = ''; // Clear the input value
        }
        if (year < minYear || year > currentYear) {
            setInfantError(`The year must be between ${minYear} and ${currentYear}!`);
            event.target.setCustomValidity(`The year must be between ${minYear} and ${currentYear}`);
            event.target.value = ''; // Clear the input value
        }
        else {
            const values = [...infantTravellers];
            const updatedValue = event.target.name;
            values[index][updatedValue] = event.target.value;
            setInfantTravellers(values);
            setInfantError('');
        }
    };
    const handleKidsChange = (index, event) => {
        const values = [...kidTravellers];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;
        setKidTravellers(values);
    };
    const handleInfantChange = (index, event) => {
        const values = [...infantTravellers];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;
        setInfantTravellers(values);
    };
    const GetUserData = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETUSERBYID + "?Id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded) {
                    setEmail(response.data.response.data.email)
                    setMobile(response.data.response.data.phoneNumber)
                }
            });
    };
    const [minDate, setMinDate] = useState('');
    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setMinDate(today);
    }, []);
    useEffect(() => {
        if (state === null) {
            navigate('/searchflights')
        }
        else if (params === null) {
            navigate('/searchflights')
        }
        else if (state.flightsdata === null) {
            navigate('/searchflights')
        }
        else {
            setLoading(true);
            if (localStorage.getItem('userId') !== null) {
                GetUserData();
            }
            setSearchData(state.searchData);
            setTotalTravellers(parseInt(params.adults) + parseInt(params.kids) + parseInt(params.infants))
            const avalues = [...adultTravellers];
            if (adultTravellers.length < params.adults) {
                for (var i = 0; i < params.adults; i++) {
                    avalues.push({ title: "Mr", firstName: "", lastName: "", type: "ADULT", dateOfBirth: "", passportNo: "", passportNationality: "IN", passbookIssueDate: "", passbookExpireDate: "" });
                    setAdultTravellers(avalues);
                }
            }
            //setTotalKids(params.kids)
            const kvalues = [...kidTravellers];
            if (kidTravellers.length < params.kids) {
                for (var i = 0; i < params.kids; i++) {
                    kvalues.push({ title: "Master", firstName: "", lastName: "", type: "CHILD", dateOfBirth: "", passportNo: "", passportNationality: "IN", passbookIssueDate: "", passbookExpireDate: "" });
                    setKidTravellers(kvalues);
                }
            }
            //setTotalInfants(params.infants)
            const ivalues = [...infantTravellers];
            if (infantTravellers.length < params.infants) {
                for (var i = 0; i < params.infants; i++) {
                    ivalues.push({ title: "Master", firstName: "", lastName: "", type: "INFANT", dateOfBirth: "", passportNo: "", passportNationality: "IN", passbookIssueDate: "", passbookExpireDate: "" });
                    setInfantTravellers(ivalues);
                }
            }
            setFlightData(state.flightsdata)
            setSegments(state.flightsdata.tFSegments)
            setBasePrice(state.flightsdata.tFPriceDetails.basePrice)
            setTax(state.flightsdata.tFPriceDetails.tax)
            setTotalPrice(state.flightsdata.tFPriceDetails.totalPrice)
            setTotalFare(state.flightsdata.tFPriceDetails.totalFare)
            setConvenienceFee(state.flightsdata.tFPriceDetails.convenienceFee)
            setPaymentGatewayFee(state.flightsdata.tFPriceDetails.paymentGatewayFee)
            setGST(state.flightsdata.tFPriceDetails.gst)
            setGSTAmount(state.flightsdata.tFPriceDetails.gstAmount)
            setFlightPriceData(state.flightsdata.tfPriceDetails);
            var tempsupplierids = [];
            tempsupplierids = [...tempsupplierids, state.flightsdata.supplierLegId];
            setSupplierIds(tempsupplierids);
            setLoading(false);
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <section className="pt-3 gray-simple">
                        <div className="container">
                            <div className="row">
                                {/* Breadcrumb */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Home</Link></li>
                                            <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Flight</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{searchData.cityFrom} To {searchData.cityTo}</li>
                                        </ol>
                                    </nav>
                                </div>
                                {/* Flight Info */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="col-xl-9 col-lg-8 col-md-12">
                                            <div className="card border-0 mb-4">
                                                <div className="card-body">
                                                    <div className="crd-block d-md-flex align-items-start justify-content-start">
                                                        <div className="crd-heaader-0 flex-shrink-0 mb-3 mb-md-0">
                                                            <div className="square--70 rounded-2 bg-light-primary text-primary fs-3"><i className="fa-solid fa-plane" /></div>
                                                        </div>
                                                        <div className="crd-heaader-first ps-md-3">
                                                            <div className="d-inline-flex align-items-center mb-1">
                                                                <span className="label fw-medium bg-light-success text-success">{searchData.cabinClass} CLASS</span>
                                                            </div>
                                                            <div className="d-block">
                                                                <h4 className="mb-0">{searchData.cityFrom}({searchData.locationFrom})<span className="text-muted-2 mx-3"><i className="fa-solid fa-arrow-right" /></span>{searchData.cityTo}({searchData.locationTo})</h4>
                                                                <div className="explotter-info">
                                                                    <p className="detail ellipsis-container fw-semibold">
                                                                        <span className="ellipsis-item__normal">{moment(searchData.departureDate).format('DD MMM YYYY')}</span>
                                                                        <span className="separate ellipsis-item__normal" />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Flight Info */}
                                            {segments.length > 0 && segments.map((seg, index) =>
                                                <div>
                                                    <div className="card border-0 mb-4">
                                                        <div className="card-body">
                                                            <div className="flights-accordion">
                                                                <div className="flights-list-item">
                                                                    <div className="row gy-4 align-items-center justify-content-between">
                                                                        <div className="col">
                                                                            <div className="row mb-4">
                                                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                                                    <div className="d-flex align-items-center mb-2">
                                                                                        <span className="label bg-light-primary text-primary me-2">Departure</span>
                                                                                        <span className="text-muted text-sm">{moment(seg.tFDepartureData.departureDateTime).format('DD MMM YYYY')}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                                                    <div className="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                                                        <div className="col-sm-auto">
                                                                                            <div className="d-flex align-items-center justify-content-start">
                                                                                                <div className="d-start fl-pic">
                                                                                                    <img className="img-fluid" src={"/assets/images/airlinelogos/" + seg.airlineCode + ".png"} width={45} alt="image" />
                                                                                                </div>
                                                                                                <div className="d-end fl-title ps-2">
                                                                                                    <div className="text-dark fw-medium">{seg.airline}</div>
                                                                                                    <div className="text-sm text-muted">{seg.equimentType + "-" + seg.flightNumber}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className="row gx-3 align-items-center">
                                                                                                <div className="col-auto">
                                                                                                    <div className="text-dark fw-bold">{moment(seg.tFDepartureData.departureDateTime).format('hh:mm A')}</div>
                                                                                                    <div className="text-muted text-sm fw-medium">{seg.tFDepartureData.airportName}, {seg.tFDepartureData.city}</div>
                                                                                                </div>
                                                                                                <div className="col text-center">
                                                                                                    <div className="flightLine departure">
                                                                                                        <div />
                                                                                                        <div />
                                                                                                    </div>
                                                                                                    <div className="text-muted text-sm fw-medium mt-3">{getTimeFromMins(seg.duration)}</div>
                                                                                                </div>
                                                                                                <div className="col-auto">
                                                                                                    <div className="text-dark fw-bold">{moment(seg.tFArrivalData.arrivalDateTime).format('hh:mm A')}</div>
                                                                                                    <div className="text-muted text-sm fw-medium">{seg.tFArrivalData.airportName}, {seg.tFArrivalData.city}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-auto">
                                                                                            <div className="col-6 col-sm col-md-auto text-end order-sm-1"><a className="text-1" data-bs-toggle="modal" data-bs-target={"#fare-rules" + index} href>Fare Rules</a></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div id={"fare-rules" + index} className="modal fade" role="dialog" aria-hidden="true">
                                                                                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                                                                        <div className="modal-content">
                                                                                            <div className="modal-header">
                                                                                                <h5 className="modal-title">Fare Rules</h5>
                                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                                            </div>
                                                                                            <div className="modal-body">
                                                                                                <ul className="nav nav-tabs" id="departureTab" role="tablist">
                                                                                                    <li className="nav-item"> <a className="nav-link active" id={"second-tab" + index} data-bs-toggle="tab" href={"#second" + index} role="tab" aria-controls={"second" + index} aria-selected="false">Fare Details</a> </li>
                                                                                                    <li className="nav-item"> <a className="nav-link" id={"third-tab" + index} data-bs-toggle="tab" href={"#third" + index} role="tab" aria-controls={"third" + index} aria-selected="false">Baggage Details</a> </li>
                                                                                                    <li className="nav-item"> <a className="nav-link" id={"fourth-tab" + index} data-bs-toggle="tab" href={"#fourth" + index} role="tab" aria-controls={"fourth" + index} aria-selected="false">Cancellation Fee</a> </li>
                                                                                                </ul>
                                                                                                <div className="tab-content my-3" id="departureContent">
                                                                                                    <div className="tab-pane fade show active" id={"second" + index} role="tabpanel" aria-labelledby="second-tab">
                                                                                                        <div className="table-responsive-md">
                                                                                                            <table className="table table-hover table-bordered">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th></th>
                                                                                                                        <th className="text-center">Base Fare</th>
                                                                                                                        <td className="text-center">Airline Taxes</td>
                                                                                                                        <td className="text-center">Total</td>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td>Adult</td>
                                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.baseFare)}</td>
                                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.tax)}</td>
                                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.totalFare)}</td>
                                                                                                                    </tr>
                                                                                                                    {flightData.tFPriceDetails.tFKids !== null ?
                                                                                                                        <tr>
                                                                                                                            <td>Kid</td>
                                                                                                                            <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.baseFare)}</td>
                                                                                                                            <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.tax)}</td>
                                                                                                                            <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.totalFare)}</td>
                                                                                                                        </tr>
                                                                                                                        : ""
                                                                                                                    }
                                                                                                                    {flightData.tFPriceDetails.tFInfants !== null ?
                                                                                                                        <tr>
                                                                                                                            <td>Infant</td>
                                                                                                                            <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.baseFare)}</td>
                                                                                                                            <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.tax)}</td>
                                                                                                                            <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.totalFare)}</td>
                                                                                                                        </tr> : ""
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="tab-pane fade" id={"third" + index} role="tabpanel" aria-labelledby="third-tab">
                                                                                                        <div className="table-responsive-md">
                                                                                                            <table className="table table-hover table-bordered">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>&nbsp;</th>
                                                                                                                        <td className="text-center">Cabin</td>
                                                                                                                        <td className="text-center">Check-In</td>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td>Adult</td>
                                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFAdults.cabinBag}</td>
                                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFAdults.checkinBag}</td>
                                                                                                                    </tr>
                                                                                                                    {flightData.tFPriceDetails.tFKids !== null ?
                                                                                                                        <tr>
                                                                                                                            <td>Child</td>
                                                                                                                            <td className="text-center">{flightData.tFPriceDetails.tFKids.cabinBag}</td>
                                                                                                                            <td className="text-center">{flightData.tFPriceDetails.tFKids.checkinBag}</td>
                                                                                                                        </tr>
                                                                                                                        :
                                                                                                                        <tr>
                                                                                                                            <td>Child</td>
                                                                                                                            <td className="text-center">7 Kg</td>
                                                                                                                            <td className="text-center">15 Kg</td>
                                                                                                                        </tr>
                                                                                                                    }
                                                                                                                    {flightData.tFPriceDetails.tFInfants !== null ?
                                                                                                                        <tr>
                                                                                                                            <td>Infant</td>
                                                                                                                            <td className="text-center">{flightData.tFPriceDetails.tFInfants.cabinBag}</td>
                                                                                                                            <td className="text-center">{flightData.tFPriceDetails.tFInfants.checkinBag}</td>
                                                                                                                        </tr>
                                                                                                                        :
                                                                                                                        <tr>
                                                                                                                            <td>Infant</td>
                                                                                                                            <td className="text-center">0 Kg</td>
                                                                                                                            <td className="text-center">0 Kg</td>
                                                                                                                        </tr>
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="tab-pane fade" id={"fourth" + index} role="tabpanel" aria-labelledby="fourth-tab">
                                                                                                        <p className="fw-bold">Terms &amp; Conditions</p>
                                                                                                        <ul>
                                                                                                            <li>The penalty is subject to 4 hrs before departure. No Changes are allowed after that.</li>
                                                                                                            <li>The charges are per passenger per sector.</li>
                                                                                                            <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                                                                                                            <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                                                                                                            <li>No Baggage Allowance for Infants</li>
                                                                                                            <li>Airline penalty needs to be reconfirmed prior to any amendments or cancellation.</li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {seg.connectingTime !== 0 ?
                                                        <div className="card border-0 mb-4">
                                                            <div className="card-body">
                                                                <div className="col text-center">
                                                                    <div className="flightLine departure">
                                                                        <div />
                                                                        <div />
                                                                    </div>
                                                                    <div className="text-muted text-sm fw-medium mt-3">{getTimeFromMins(seg.connectingTime)}</div>
                                                                    <div>Connecting Time</div>
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                </div>
                                            )}

                                            {/* Traveler Details */}
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                {/* Overview */}
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h6 className="fw-semibold mb-0">Traveler Details</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="bg-success bg-opacity-10 rounded-2 p-3 mb-3">
                                                            <p className="h6 text-md mb-0"><span className="badge bg-success me-2">New</span>Please enter your name as per your passport ID</p>
                                                        </div>
                                                        <div className="full-width d-flex flex-column mb-4 position-relative">
                                                            <div className="row align-items-stat">
                                                                {adultTravellers.length > 0 && adultTravellers.map((adult, index) =>
                                                                    <div>
                                                                        <p className="fw-600">Adult {parseInt(index + 1)}</p>
                                                                        <div className="row g-3 mb-4">
                                                                            <div className="col-sm-3">
                                                                                <labe>Title</labe>
                                                                                <select className="form-control" name="title" required value={adult.title} onChange={(event) => handleAdultChange(index, event)}>
                                                                                    <option value="Mr">Mr</option>
                                                                                    <option value="Mrs">Mrs</option>
                                                                                    <option value="Ms">Ms</option>
                                                                                    <option value="Master">Master</option>
                                                                                </select>
                                                                                {errors.title === false ? (<div className="validationerror">Please select title </div>) : ('')}
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <labe>Givenname</labe>
                                                                                <input className="form-control" name="firstName" value={adult.firstName} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter First Name" type="text" />
                                                                                {errors.firstName === false ? (<div className="validationerror">Please enter firstName </div>) : ('')}
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <labe>Surname</labe>
                                                                                <input className="form-control" name="lastName" value={adult.lastName} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter Surname" type="text" />
                                                                                {errors.lastName === false ? (<div className="validationerror">Please enter surname </div>) : ('')}
                                                                            </div>
                                                                            {params.isDomestic === 'INTERNATIONAL' ?
                                                                                <div className="col-sm-3">
                                                                                    <labe>Date Of Birth</labe>
                                                                                    <input className="form-control" name="dateOfBirth" value={adult.dateOfBirth} onBlur={(event) => validateAdultDate(index, event)} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter Date Of Birth" type="date" />
                                                                                    {errors.dateOfBirth === false ? (<div className="validationerror">Please enter date of birth </div>) : ('')}
                                                                                    {errorAdult && <div className="validationerror">{errorAdult}</div>}
                                                                                </div>
                                                                                : ""}
                                                                        </div>
                                                                        {params.isDomestic === 'INTERNATIONAL' ?
                                                                            <div className="row g-3 mb-4">
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport No</label>
                                                                                    <input className="form-control" name="passportNo" value={adult.passportNo} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter Passport No" type="text" maxLength={10} />
                                                                                    {errors.passportNo === false ? (<div className="validationerror">Please enter passport no </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Nationality</label>
                                                                                    <select className="form-control" name="passportNationality" onChange={(event) => handleAdultChange(index, event)} value={adult.passportNationality} required>
                                                                                        {countries.length > 0 && countries.map(cs =>
                                                                                            <option value={cs.code}>{cs.name}</option>
                                                                                        )}
                                                                                    </select>
                                                                                    {errors.passportNationality === false ? (<div className="validationerror">Please enter passportNationality </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport Issue Date</label>
                                                                                    <input className="form-control" name="passbookIssueDate" value={adult.passbookIssueDate} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter Passport Issue Date" type="date" />
                                                                                    {errors.passbookIssueDate === false ? (<div className="validationerror">Please enter passbookIssueDate </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport Expiry Date</label>
                                                                                    <input className="form-control" min={minDate} name="passbookExpireDate" value={adult.passbookExpireDate} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter Passport Expiry Date" type="date" />
                                                                                    {errors.passbookExpireDate === false ? (<div className="validationerror">Please enter passbookExpireDate </div>) : ('')}
                                                                                </div>
                                                                            </div>
                                                                            : ""}
                                                                    </div>
                                                                )}
                                                                {kidTravellers.length > 0 && kidTravellers.map((adult, index) =>
                                                                    <div>
                                                                        <p className="fw-600">CHILD {parseInt(index + 1)}</p>
                                                                        <div className="row g-3 mb-3">
                                                                            <div className="col-sm-3">
                                                                                <label>Title</label>
                                                                                <select className="form-control" name="title" required value={adult.title} onChange={(event) => handleKidsChange(index, event)}>
                                                                                    <option value="Ms">Ms</option>
                                                                                    <option value="Master">Master</option>
                                                                                </select>
                                                                                {errors.title === false ? (<div className="validationerror">Please select title </div>) : ('')}
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label>Givenname</label>
                                                                                <input className="form-control" name="firstName" value={adult.firstName} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Givenname" type="text" />
                                                                                {errors.firstName === false ? (<div className="validationerror">Please enter givenname </div>) : ('')}
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label>Surname</label>
                                                                                <input className="form-control" name="lastName" value={adult.lastName} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Surname" type="text" />
                                                                                {errors.lastName === false ? (<div className="validationerror">Please enter surname </div>) : ('')}
                                                                            </div>
                                                                            {params.isDomestic === 'INTERNATIONAL' ?
                                                                                <div className="col-sm-3">
                                                                                    <label>Date Of Birth</label>
                                                                                    <input className="form-control" name="dateOfBirth" value={adult.dateOfBirth} onBlur={(event) => validateKidsDate(index, event)} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Date Of Birth" type="date" />
                                                                                    {errors.dateOfBirth === false ? (<div className="validationerror">Please enter date of birth </div>) : ('')}
                                                                                    {errorKid && <div className="validationerror">{errorKid}</div>}
                                                                                </div> : ""}
                                                                        </div>
                                                                        {params.isDomestic === 'INTERNATIONAL' ?
                                                                            <div className="row g-3 mb-3">
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport No</label>
                                                                                    <input className="form-control" name="passportNo" value={adult.passportNo} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Passport No" type="text" maxLength={10} />
                                                                                    {errors.passportNo === false ? (<div className="validationerror">Please enter passport no </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Nationality</label>
                                                                                    <select className="form-control" name="passportNationality" onChange={(event) => handleKidsChange(index, event)} value={adult.passportNationality} required>
                                                                                        {countries.length > 0 && countries.map(cs =>
                                                                                            <option value={cs.code}>{cs.name}</option>
                                                                                        )}
                                                                                    </select>
                                                                                    {errors.passportNationality === false ? (<div className="validationerror">Please enter passportNationality </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport Issue Date</label>
                                                                                    <input className="form-control" name="passbookIssueDate" value={adult.passbookIssueDate} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Passport Issue Date" type="date" />
                                                                                    {errors.passbookIssueDate === false ? (<div className="validationerror">Please enter passbookIssueDate </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport Expiry Date</label>
                                                                                    <input className="form-control" min={minDate} name="passbookExpireDate" value={adult.passbookExpireDate} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Passport Expiry Date" type="date" />
                                                                                    {errors.passbookExpireDate === false ? (<div className="validationerror">Please enter passbookExpireDate </div>) : ('')}
                                                                                </div>
                                                                            </div>
                                                                            : ""}
                                                                    </div>
                                                                )}
                                                                {infantTravellers.length > 0 && infantTravellers.map((adult, index) =>
                                                                    <div>
                                                                        <p className="fw-600">INFANT {parseInt(index + 1)}</p>
                                                                        <div className="row g-3 mb-4">
                                                                            <div className="col-sm-3">
                                                                                <label>Title</label>
                                                                                <select className="form-control" name="title" required value={adult.title} onChange={(event) => handleInfantChange(index, event)}>
                                                                                    <option value="Ms">Ms</option>
                                                                                    <option value="Master">Master</option>
                                                                                </select>
                                                                                {errors.title === false ? (<div className="validationerror">Please select title </div>) : ('')}
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label>First Name</label>
                                                                                <input className="form-control" name="firstName" value={adult.firstName} onChange={(event) => handleInfantChange(index, event)} required placeholder="Enter First Name" type="text" />
                                                                                {errors.firstName === false ? (<div className="validationerror">Please enter firstName </div>) : ('')}
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label>Last Name</label>
                                                                                <input className="form-control" name="lastName" value={adult.lastName} onChange={(event) => handleInfantChange(index, event)} required placeholder="Enter Last Name" type="text" />
                                                                                {errors.lastName === false ? (<div className="validationerror">Please enter lastName </div>) : ('')}
                                                                            </div>
                                                                            {params.isDomestic === 'INTERNATIONAL' ?
                                                                                <div className="col-sm-3">
                                                                                    <label>Date Of Birth</label>
                                                                                    <input className="form-control" name="dateOfBirth" value={adult.dateOfBirth} onBlur={(event) => validateInfantDate(index, event)} onChange={(event) => handleInfantChange(index, event)} required placeholder="Enter Date Of Birth" type="date" />
                                                                                    {errors.dateOfBirth === false ? (<div className="validationerror">Please enter date of birth </div>) : ('')}
                                                                                    {errorInfant && <div className="validationerror">{errorInfant}</div>}
                                                                                </div> : ""}
                                                                        </div>
                                                                        {params.isDomestic === 'INTERNATIONAL' ?
                                                                            <div className="row g-3 mb-4">
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport No</label>
                                                                                    <input className="form-control" name="passportNo" value={adult.passportNo} onChange={(event) => handleInfantChange(index, event)} required placeholder="Enter Passport No" type="text" maxLength={10} />
                                                                                    {errors.passportNo === false ? (<div className="validationerror">Please enter passport no </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Nationality</label>
                                                                                    <select className="form-control" name="passportNationality" onChange={(event) => handleInfantChange(index, event)} value={adult.passportNationality} required>
                                                                                        {countries.length > 0 && countries.map(cs =>
                                                                                            <option value={cs.code}>{cs.name}</option>
                                                                                        )}
                                                                                    </select>
                                                                                    {errors.passportNationality === false ? (<div className="validationerror">Please enter passport Nationality </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport Issue Date</label>
                                                                                    <input className="form-control" name="passbookIssueDate" value={adult.passbookIssueDate} onChange={(event) => handleInfantChange(index, event)} required placeholder="Enter Passport Issue Date" type="date" />
                                                                                    {errors.passbookIssueDate === false ? (<div className="validationerror">Please enter passbook IssueDate </div>) : ('')}
                                                                                </div>
                                                                                <div className="col-sm-3">
                                                                                    <label>Passport Expiry Date</label>
                                                                                    <input className="form-control" min={minDate} name="passbookExpireDate" value={adult.passbookExpireDate} onChange={(event) => handleInfantChange(index, event)} required placeholder="Enter Passport Expiry Date" type="date" />
                                                                                    {errors.passbookExpireDate === false ? (<div className="validationerror">Please enter passbook ExpireDate </div>) : ('')}
                                                                                </div>
                                                                            </div>
                                                                            : ""}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="full-width d-flex flex-column mb-2 position-relative">
                                                            <div className="row align-items-stat">
                                                                <div className="col-xl-12 col-lg-12 col-md-12 mb-2">
                                                                    <h5>Personal Information</h5>
                                                                </div>
                                                                <div className="col-xl-6 col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Email Address</label>
                                                                        <input type="text" maxLength={45} value={email} name="email" onChange={handleEmailChange} className="form-control" placeholder="Email Here" />
                                                                        {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6 col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Mobile number</label>
                                                                        <input type="number" maxLength={12} value={mobile} name="mobile" onChange={handleMobileChange} className="form-control" placeholder="Mobile Number" />
                                                                        {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6 col-lg-12 col-md-12">
                                                                </div>
                                                                <div className="col-xl-6 col-lg-12 col-md-12">
                                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                                        <button className="btn fw-medium btn-danger full-width m-2" type="button" onClick={handleFlightSearch}>Back to Flights List</button>
                                                                        <button className="btn fw-medium btn-primary full-width" type="button" onClick={e => handleSubmit(e)}>Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Sidebar */}
                                        <div className="col-xl-3 col-lg-4 col-md-12">
                                            <div className="side-block card rounded-2 p-3">
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <h5 className="fw-semibold fs-6 mb-0">Payment Summary</h5>
                                                </div>
                                                <div className="card mb-4 mt-lg-0 mt-4">
                                                    <div className="card-body py-2">
                                                        <div className="price-summary">
                                                            <ul className="list-group">
                                                                <li className="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                                    Base Fare
                                                                    <span className="fw-semibold text-dark">INR {Math.round(baseprice)}</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                                    Airline Taxes
                                                                    <span className="fw-semibold text-dark">INR {Math.round(tax)}</span>
                                                                </li>
                                                                {priceChange !== "" ?
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                                        Note :
                                                                        <span className="fw-semibold text-success">{priceChange}</span>
                                                                    </li> : ""}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer bg-white border-top py-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="fw-semibold text-muted-2 mb-0">Price</p>
                                                            <p className="fw-semibold text-primary mb-0">INR {Math.round(totalFare)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}</div>
    )
}