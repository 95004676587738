import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import "react-datepicker/dist/react-datepicker.css";
import UserTab from "./UserTab";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import Header from "../Layout/Header";
export default function FlightBookingVoucher() {
    const params = useParams();
    const navigate = useNavigate();
    const [flightData, setFlightData] = useState([])
    const [segments, setSegments] = useState([])
    const [travellers, setTravellers] = useState([])
    const [pnrObject, setpnrObject] = useState([])
    const [loading, setLoading] = useState(false);
    const contentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    const downloadPDF = () => {
        const input = contentRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('flightbooking.pdf');
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    const GetFlightBookingDetails = () => {
        setLoading(true)
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETFLIGHTBOOKINGBYID + "?Id=" + params.flightBookingId, { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded) {
                    setFlightData(response.data.response.data);
                    setTravellers(response.data.response.data.travelers);
                    setSegments(response.data.response.data.tfSegments);
                    if (response.data.response.data != null)
                        if (response.data.response.data.pnr != null) {
                            setpnrObject(JSON.parse(response.data.response.data.pnr));
                        }
                }
                setLoading(false)
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetFlightBookingDetails();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="dashboard-menus border-top d-none d-lg-block" style={{ backgroundColor: 'cadetblue' }}>
                    <div className="container">
                        <UserTab></UserTab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Flight Booking Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="card mb-3">
                                    <div className="card-body px-xl-5 px-lg-4 py-lg-5 py-4 px-4">
                                        <div className="d-flex align-items-center justify-content-center m-3">                                   </div>
                                        <div ref={contentRef}>
                                            <div className="d-flex align-items-center justify-content-center flex-column text-center mb-3 mt-2">
                                                <h3 className="mt-3">Booking Confirmation!</h3>
                                                <span>Help Line : {APIConfig.COMAPNYPHONE}</span>
                                            </div>
                                            <div className="p-3">
                                                <p className="mt-0 lh-base text-1 text-black">Dear Traveler, Following are the flight booking details</p>
                                                <p className="mt-0 lh-base text-1 text-black">
                                                    Booking ID: {flightData.bookingNo}<br />
                                                    Class: {flightData.cabinClass}
                                                </p>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center flex-column">
                                                <hr className="mb-4" />
                                                {travellers.length > 0 ?
                                                    <div className="table-responsive-md" style={{ width: "100%" }}>
                                                        <table className="table table-hover table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center">Passenger Names</th>
                                                                    <th className="text-center">PNR</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center">
                                                                        {travellers.map((t, index) =>
                                                                            <ul className="list-unstyled">
                                                                                <li>{t.title}. {t.firstName} {t.lastName}</li>
                                                                            </ul>
                                                                        )}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <ul>
                                                                            {Object.entries(pnrObject).map(([key, value], index) => (
                                                                                <li key={index}>
                                                                                    {key}: {value}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> : ""}
                                                    <hr className="mb-4" />
                                                {segments.length > 0 && segments.map((seg, index) =>
                                                    <div className="card mb-4">
                                                        <div className="card-header">
                                                            <div className="row align-items-center trip-title">
                                                                <div className="col-5 col-sm-auto text-center text-sm-start">
                                                                    <h5 className="m-0 trip-place">{seg.tFDepartureData.city}, {seg.tFDepartureData.country}</h5>
                                                                </div>
                                                                <div className="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                                                                <div className="col-5 col-sm-auto text-center text-sm-start">
                                                                    <h5 className="m-0 trip-place">{seg.tFArrivalData.city}, {seg.tFArrivalData.country}</h5>
                                                                </div>
                                                                <div className="col-12 mt-1 d-block d-md-none" />
                                                                <div className="col-6 col-sm col-md-auto text-3 date">{moment(seg.tFDepartureData.departureDateTime).format('Do MMM YYYY ddd')}</div>
                                                                <div className="col col-md-auto text-center ms-auto order-sm-0">
                                                                {flightData.bookingStatus === "CONFIRMED" ?
                                                                        <span className="badge bg-success py-1 px-2 fw-normal text-0">Confirmed <i className="fas fa-check-circle" /></span>
                                                                        : flightData.bookingStatus === "PENDING" ?
                                                                            <span className="badge bg-danger py-1 px-2 fw-normal text-0">PENDING <i className="fas fa-check-circle" /></span>
                                                                            : <span className="badge bg-danger py-1 px-2 fw-normal text-0">FAILED <i className="fas fa-check-circle" /></span>
                                                                    }
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-3 text-center text-md-start d-lg-flex company-info"> <span className="align-middle"><img className="img-fluid" alt src={"/assets/images/airlinelogos/" + seg.airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-2 text-dark mt-1 mt-lg-0">{seg.airline}</span> <small className="text-muted d-block">{seg.equimentType + "-" + seg.flightNumber}</small> </span> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">Departure</small>
                                                                    {seg.tFDepartureData.airportName},<br />
                                                                    {seg.tFDepartureData.city}
                                                                </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-3 text-dark">{getTimeFromMins(seg.duration)}</span> <small className="text-muted d-block">Duration</small> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">Arrival</small>
                                                                    {seg.tFArrivalData.airportName},<br />
                                                                    {seg.tFArrivalData.city}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}                                                
                                            </div>
                                        </div>
                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            <button onClick={downloadPDF} className="btn btn-md btn-primary fw-semibold mx-2">Download Voucher</button>
                                            <Link to={'/flight-bookings-invoice/' + params.flightBookingId} className="btn btn-md btn-success fw-semibold mx-2">View Invoice</Link>
                                            <button onClick={handlePrint} className="btn btn-md btn-warning fw-semibold mx-2">Print Voucher</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}