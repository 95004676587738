module.exports = {
  COMPANYNAME: 'GRR TOURS AND TRAVELS PRIVATE LIMITED',
  COMPANYEMAIL: 'info@flywithgrr.com',
  COMAPNYPHONE: '9491807865',
  COMPANYCITY: 'HYDERABAD',
  COMPANYGST: '36AAKCG8669C1Z8',
  CURRENCY: 'INR',
  COMPANYADDRESS:'Shop No: 111 & 112, 1st Floor, Ashoka Plaza,Beside Golkonda Hotel,Masab Tank, Hyderabad,Telangana 500004',
  APILOCALURL: 'https://localhost:7262/api/',
  APISERVERURL: 'https://grrapi.azurewebsites.net/api/',
  APIACTIVATEURL: 'https://grrapi.azurewebsites.net/api/',


  LOGINUSER: 'home/login',
  REGISTERUSER: 'home/registeruser',
  CHECKEMAIL: 'home/check-email',
  CONFIRMEMAIL: 'home/confirmemail',
  SENDRESETPASSWORD: 'home/sendresetpassword',
  RESETPASSWORD: 'home/resetpassword',
  GETPROMOCODES:'promocode',
  GETPAYMENTGATEWAYS:'Payment',

  REGISTERAGENT: 'home/agent-register',
  LOGINAGENT: 'home/agent-login',
  GETAGENTBYID: 'agent/GetByAgent',
  UPDATEAGENT: 'Agent',

  GETUSERBYID: 'User/GetById',
  UPDATEUSER: 'User',
  CHANGEPASSWORD: 'User/ChangePassword',

  GETALLSTUDYCONTENTS: 'StudyContent/GetAll',
  GETSTUDYCONTENTS: 'StudyContent',
  CREATESTUDYCONTENT: 'StudyContent/Create',
  UPDATESTUDYCONTENT: 'StudyContent',
  DELETESTUDYCONTENT: 'StudyContent',
  GETSTUDYCONTENTBYID: 'StudyContent/GetById',
  GETSTUDYCONTENTBYDESTINATIONID: 'StudyContent/GetByDestinationId',

  GETALLSTUDYDESTINATIONS: 'StudyDestination/GetAll',
  GETSTUDYDESTINATIONS: 'StudyDestination',
  CREATESTUDYDESTINATION: 'StudyDestination/Create',
  UPDATESTUDYDESTINATION: 'StudyDestination',
  DELETESTUDYDESTINATION: 'StudyDestination',
  GETSTUDYDESTINATIONBYID: 'StudyDestination',

  GETALLSTUDYENQUIRY: 'StudyEnquiry/GetAll',
  GETSTUDYENQUIRY: 'StudyEnquiry',
  CREATESTUDYENQUIRY: 'StudyEnquiry/Create',
  UPDATESTUDYENQUIRY: 'StudyEnquiry',
  DELETESTUDYENQUIRY: 'StudyEnquiry',
  GETSTUDYENQUIRYBYID: 'StudyEnquiry',

  GETALLSUPPLIERS: 'Supplier/GetAll',
  GETSUPPLIERS: 'Supplier',
  CREATESUPPLIER: 'Supplier/Create',
  UPDATESUPPLIER: 'Supplier',
  DELETESUPPLIER: 'Supplier',
  GETSUPPLIERBYID: 'Supplier/GetById',

  GETALLSUPPLIERCONTACTS: 'SupplierContact/GetAll',
  GETSUPPLIERCONTACTS: 'SupplierContact',
  CREATESUPPLIERCONTACT: 'SupplierContact/Create',
  UPDATESUPPLIERCONTACT: 'SupplierContact',
  DELETESUPPLIERCONTACT: 'SupplierContact',
  GETSUPPLIERCONTACTBYSUPPLIERID: 'SupplierContact/GetById',
  GETBYSUPPLIERID: 'SupplierContact/GetById',

  SEARCHHOLIDAYDESTINATIONS: 'Destination/Search',
  GETALLHOLIDAYDESTINATIONS: 'Destination/GetAll',
  GETHOLIDAYDESTINATIONS: 'Destination',
  CREATEHOLIDAYDESTINATION: 'Destination/Create',
  UPDATEHOLIDAYDESTINATION: 'Destination',
  DELETEHOLIDAYDESTINATION: 'Destination',
  GETHOLIDAYDESTINATIONBYID: 'Destination',

  GETALLCUSTOMERS: 'Customer/GetAll',
  GETCUSTOMERS: 'Customer',
  CREATECUSTOMER: 'Customer/Create',
  UPDATECUSTOMER: 'Customer',
  DELETECUSTOMER: 'Customer',
  GETCUSTOMERBYID: 'Customer',


  GETALLWEDDINGENQUIRYS: 'WeddingEnquiry/GetAll',
  GETWEDDINGENQUIRYS: 'WeddingEnquiry',
  CREATEWEDDINGENQUIRY: 'WeddingEnquiry/Create',
  UPDATEWEDDINGENQUIRY: 'WeddingEnquiry',
  DELETEWEDDINGENQUIRY: 'WeddingEnquiry',
  GETWEDDINGENQUIRYBYID: 'WeddingEnquiry',

  GETALLWEDDINGDESTINATIONS: 'WeddingDestination/GetAll',
  GETWEDDINGDESTINATIONS: 'WeddingDestination',
  CREATEWEDDINGDESTINATION: 'WeddingDestination/Create',
  UPDATEWEDDINGDESTINATION: 'WeddingDestination',
  DELETEWEDDINGDESTINATION: 'WeddingDestination',
  GETWEDDINGDESTINATIONBYID: 'WeddingDestination',

  GETWEDDINGCONTENTBYDESTINATION: 'WeddingContent/GetByDestination',

  GETALLHOLIDAYENQUIRYS: 'HolidayEnquiry/GetAll',
  GETHOLIDAYENQUIRYS: 'HolidayEnquiry',
  CREATEHOLIDAYENQUIRY: 'HolidayEnquiry/Create',
  UPDATEHOLIDAYENQUIRY: 'HolidayEnquiry',
  DELETEHOLIDAYENQUIRY: 'HolidayEnquiry',
  GETHOLIDAYENQUIRYBYID: 'HolidayEnquiry',

  GETALLPACKAGEENQUIRYS: 'PackageEnquiry/GetAll',
  GETPACKAGEENQUIRYS: 'PackageEnquiry',
  CREATEPACKAGEENQUIRY: 'PackageEnquiry/Create',
  UPDATEPACKAGEENQUIRY: 'PackageEnquiry',
  DELETEPACKAGEENQUIRY: 'PackageEnquiry',
  GETPACKAGEENQUIRYBYID: 'PackageEnquiry',

  GETALLTOURS: 'Tour/GetAll',
  GETTOURS: 'Tour',
  CREATETOUR: 'Tour/Create',
  UPDATETOUR: 'Tour',
  DELETETOUR: 'Tour',
  GETTOURBYID: 'Tour',
  GETTOURSBYDESTINATIONID: 'Tour/GetByDestinationId',
  GETTOURSBYDESTINATION: 'Tour/GetByDestination',

  GETALLHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARYBYPACKAGEID: 'HolidayItinerary/GetByPackageId',
  CREATEHOLIDAYITINERARY: 'HolidayItinerary/Create',
  UPDATEHOLIDAYITINERARY: 'HolidayItinerary',
  DELETEHOLIDAYITINERARY: 'HolidayItinerary',
  GETHOLIDAYITINERARYBYID: 'HolidayItinerary',

  GETVISACOUNTRIES: 'Visa/GetVisaCountries',
  SEARCHVISACOUNTRIES: 'Visa/SearchVisaCountries',
  GETBYVISASBYCOUNTRY: 'Visa/GetVisasByCountry',
  GETBYVISASBYCOUNTRYID: 'Visa/GetVisasByCountryId',
  GETVISABYID: 'Visa',

  GETVISAPRICE: 'VisaPrice',
  GETVISAPRICEBYCOUNTRY: 'VisaPrice/GetByCountry',
  CREATEVISAPRICE: 'VisaPrice/Create',
  UPDATEVISAPRICE: 'VisaPrice',
  DELETEVISAPRICE: 'VisaPrice',
  GETVISAPRICEBYID: 'VisaPrice',


  GETBYVISAAPPLICANT: 'VisaApplicantDocument/VisaApplicant',
  CREATE: 'VisaApplicantDocument/Create',
  UPDATE: 'VisaApplicantDocument',
  DELETE: 'VisaApplicantDocument',
  GETBYID: 'VisaApplicantDocument',

  GETALLVISAREQUEST: 'VisaRequest/GetAll',
  GETVISAREQUEST: 'VisaRequest',
  CREATEVISAREQUEST: 'VisaRequest/Create',
  UPDATEVISAREQUEST: 'VisaRequest',
  DELETEVISAREQUEST: 'VisaRequest',
  GETVISAREQUESTBYID: 'VisaRequest',
  GETVISAREQUESTBYUSERID: 'VisaRequest/GetByUserId',

  GETALLVISAAPPLICANT: 'VisaApplicant/GetAll',
  GETALLVISAAPPLICANT: 'VisaApplicant/GetByVisaRequest',
  GETVISAAPPLICANT: 'VisaApplicant',
  CREATEVISAAPPLICANT: 'VisaApplicant/Create',
  UPDATEVISAAPPLICANT: 'VisaApplicant',
  DELETEVISAAPPLICANT: 'VisaApplicant',
  GETVISAAPPLICANTBYID: 'VisaApplicant',

  PAYMENTREQUESTORDER: 'Payment/Process',
  PAYMENTCOMPLETEORDER: 'Payment/ProcessCompleteOrder',

  CREATETRANSACTION: 'Transaction/Create',

  SEARCHFLIGHTS: "Flight/Search",
  REVIEWFLIGHT: "Flight/Review",
  BOOKFLIGHT: "Flight/Book",
  GETUSERFLIGHTS: "User/GetFlightBookings",
  GETAGENTFLIGHTS: "Flight/GetByAgentId",
  GETFLIGHTBOOKINGBYID: 'Flight/GetById',

  SEARCHHOTELS: "Hotel/Search",
  HOTELDETAILS: "Hotel/Details",
  HOTELREVIEW: "Hotel/Review",
  HOTELBOOK: "Hotel/Book",
  GETUSERHOTELS: "User/GetHotelBookings",
  GETHOTELBYID: "Hotel/GetById"
}